import { SessionEndOptions, useCognitoAction } from '@/contexts/Cognito';
import { useApolloClient } from '@apollo/client';
import { useDeepLinkAuthorization } from '@/contexts/DeepLinkAuthorization';
import { useCallback } from 'react';

const useLogOut = () => {
  const { endSession } = useCognitoAction();
  const client = useApolloClient();
  const { logOut: logOutDeepLinks } = useDeepLinkAuthorization();

  // Wrap in callback with no deps, to prevent re-render triggers
  // when functions are recreated
  const clearCacheAndLogOut = useCallback(
    async (authOptions: SessionEndOptions = {}) => {
      return Promise.allSettled([
        client.clearStore(),
        logOutDeepLinks(),
        endSession(authOptions),
      ]);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps
       -- A bug in useDeepLinkAuthorization causes a re-render loop if
          logOutDeepLinks is included in the dependency array.
          We need to revisit how we pass unstable reference functions
          out of context providers.
    */
    []
  );

  return clearCacheAndLogOut;
};

export default useLogOut;
