/**
 * Map environment variables to constants for use as feature flags.
 */
export const intakeSchedulePoc =
  process.env.NEXT_PUBLIC_FF_INTAKE_SCHEDULE_FLOW === 'true';
export const conversationalAi =
  process.env.NEXT_PUBLIC_FF_CONVERSATIONAL_AI === 'true';
export const conversationalAiDebugging =
  process.env.NEXT_PUBLIC_FF_CONVERSATIONAL_AI_DEBUGGING === 'true';
export const conversationalAiStepping =
  process.env.NEXT_PUBLIC_FF_CONVERSATIONAL_AI_STEPPING === 'true';
export const signupDisabled =
  process.env.NEXT_PUBLIC_FF_DISABLE_SIGNUP === 'true';
