import {
  MockedProvider,
  MockedProviderProps,
  MockedResponse,
  MockLink,
} from '@apollo/react-testing';
import { FunctionComponent } from 'react';
import { ApolloClientAuthenticationContext } from './AuthenticatedApolloProvider';
import { ApolloLink } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { makeCache } from '@/lib/graphql/apollo-client';

interface MockedAuthenticatedApolloProviderProps
  extends Omit<MockedProviderProps, 'mocks' | 'link' | 'cache'> {
  authenticated: boolean;
  mocks?: MockedResponse[] | MockedResponse;
  operationSpy?: (operation: string) => void;
  operationAndVariablesSpy?: (
    operation: string,
    variables: Record<string, any>
  ) => void;
}

/** MockedAuthenticatedApolloProvider wraps the provided MockedApolloProvider with the clientAuthenticated
 * context value set to true.
 *
 * It also exposes two optional spy functions that can be used to test the operations called.
 */
export const MockedAuthenticatedApolloProvider: FunctionComponent<
  MockedAuthenticatedApolloProviderProps
> = ({
  children,
  authenticated,
  mocks,
  operationSpy,
  operationAndVariablesSpy,
  ...otherMockedProviderProps
}) => {
  // Because we don't have to actually set auth data on an Apollo Client, we just mirror
  // the state of the authentication context.
  // const { authenticated } = useAuthentication();

  // As a syntactic enhancement, we allow the `mocks` prop to be either a single mock
  // or an array of mocks.
  const mocksArray = Array.isArray(mocks) ? mocks : mocks ? [mocks] : [];

  // Instantiate our custom cache with Contentful model mappings:
  const cache = makeCache();

  // We manually create the MockLink rather than just assigning `mocks`, since
  // that allows us to chain additional links which in turn can be used to
  // log/debug the GraphQL operations.
  const mockLink = new MockLink(mocksArray);
  const spyLink = new ApolloLink((operation, forward) => {
    operationSpy?.(operation.operationName);
    operationAndVariablesSpy?.(operation.operationName, operation.variables);
    return forward(operation);
  });
  const stripTypesFromMutationsLink = removeTypenameFromVariables();

  const linkChain = ApolloLink.from([
    stripTypesFromMutationsLink,
    spyLink,
    mockLink,
  ]);

  return (
    <MockedProvider
      link={linkChain}
      cache={cache}
      {...otherMockedProviderProps}>
      <ApolloClientAuthenticationContext.Provider
        value={{ clientAuthenticated: authenticated }}>
        {children}
      </ApolloClientAuthenticationContext.Provider>
    </MockedProvider>
  );
};
