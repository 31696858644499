import { PropsWithChildren, useContext, type FunctionComponent } from 'react';
import useTenantDomain from '@/hooks/useTenantDomain';
import { SchedulingType } from '@/lib/graphql/types';
import { useTenantConfigQuery } from '@/lib/graphql/shared';
import { TenantConfigContext } from './TenantConfigContext';
import { UserPerspective } from '@/types/userPerspective';
import LoaderInterstitial from '@/components/LoaderInterstitial';
import ErrorInterstitial from '@/components/ErrorInterstitial';
import tenantConfigExtensions from '@/lib/config-tenant-extensions';

export interface TenantConfigLoaderProps extends PropsWithChildren {
  perspective: UserPerspective;
}

/**
 * Handles loading the tenant configuration for the current domain/subdomain.
 *
 * Uses the useTenantDomain hook, so tenant domain can be set in local/staging
 * environments using the TENANT_DOMAIN environment variable.
 */
export const TenantConfigLoader: FunctionComponent<TenantConfigLoaderProps> = ({
  children,
  perspective,
}) => {
  const { setConfig } = useContext(TenantConfigContext);
  const { domain, loading: domainLoading } = useTenantDomain();
  const { data, loading: tenantLoading } = useTenantConfigQuery({
    skip: !domain,
    variables: {
      domain: domain!,
    },
    onCompleted: (data) => {
      const tenant = data?.tenantByDomain;
      if (!tenant) {
        return;
      }

      const cognitoConfig = (tenant.cognitoConfig &&
        (perspective === UserPerspective.Staff
          ? {
              clientId: tenant.cognitoConfig.staffClientId,
              userPoolId: tenant.cognitoConfig.staffUserPoolId,
            }
          : {
              clientId: tenant.cognitoConfig.patientsClientId,
              userPoolId: tenant.cognitoConfig.patientsUserPoolId,
            })) || {
        clientId: '',
        userPoolId: '',
      };

      const extensions = tenantConfigExtensions[tenant.slug] ?? {};

      const config = {
        id: tenant.id,
        domain: domain!,
        name: tenant.name,
        slug: tenant.slug,
        cognito: cognitoConfig,
        mapboxConfig: {
          accessToken: process.env.NEXT_PUBLIC_MAPBOX_CLIENT_TOKEN,
        },
        contentfulConfig: {
          token: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN || '',
          space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || '',
          environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || '',
          tag: 'platformUpliv', //Hardcoded until we can fetch this from the BE
        },
        ...extensions,
        features: {
          ...(extensions.features || {}),
          enableCronofyScheduling:
            tenant.schedulingType === SchedulingType.Integrated,
          enableNonIntegratedScheduling:
            tenant.schedulingType ===
            SchedulingType.NonIntegratedProviderSearch,
        },
      };
      setConfig(config);
    },
  });

  const loading = domainLoading || tenantLoading;

  if (loading) {
    return (
      <LoaderInterstitial
        title="Loading…"
        devInfo={`Loading tenant configuration for ${domain}`}
      />
    );
  }

  const tenant = data?.tenantByDomain;

  if (!tenant) {
    return (
      <ErrorInterstitial
        title="Site Unavailable"
        description="This domain has not been configured."
        devInfo={`No tenant config returned for domain ${domain}`}
      />
    );
  }

  const cognito = data.tenantByDomain?.cognitoConfig;

  if (!cognito) {
    return (
      <ErrorInterstitial
        title="Site Unavailable"
        description="This domain has not been configured."
        devInfo={`Tenant config for ${domain} is missing Cognito pool configuration. Cannot proceed.`}
      />
    );
  }

  return children;
};
