import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export const addContextHeader = (
  headerName: string,
  headerValue: string | undefined | null,
  previousContext: DefaultContext
) =>
  headerValue
    ? {
        headers: {
          ...(previousContext.headers ?? {}),
          [headerName]: headerValue,
        },
      }
    : {};

export const addContextAuthHeader = (
  token: string | undefined | null,
  previousContext: DefaultContext
) =>
  token
    ? addContextHeader('authorization', `Bearer ${token}`, previousContext)
    : {};

/**
 * Create an Upload link that replaces createHttpLink extending its functionality to handle file uploads.
 * UploadLink must be a terminating apollo link.
 */
const caireHttpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPH_API_URL,
  headers: {
    'apollo-require-preflight': 'true',
  },
  fetch,
});

const dataDogErrorReportLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    import('@datadog/browser-rum-slim').then(({ datadogRum }) => {
      graphQLErrors?.forEach((error) => {
        datadogRum.addError(error);
      });
      if (networkError) {
        datadogRum.addError(networkError);
      }
    });
  }
});

const devConsoleErrorReportLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === 'development') {
    graphQLErrors?.forEach((error) => {
      console.error('GraphQL Error', error);
    });
    if (networkError) {
      console.error('GraphQL Network Error', networkError);
    }
  }
});

export const makeContentfulApiUrl = (
  baseUrl: string,
  spaceId: string,
  environmentName = 'master'
) =>
  [baseUrl, 'content/v1/spaces', spaceId, 'environments', environmentName].join(
    '/'
  );

/**
 * Contentful objects have an abnormal structure where the `id` is nested within
 * the `sys` object. This list of types is used below to generate the `typePolicies`
 * in the Apollo cache to normalize the data.
 */
const ContentfulIdTypes = [
  'GuidedJourney',
  'Lesson',
  'Page',
  'Celebration',
  'AccessibleMedia',
  'Category',
  'Tag',
];

/* Instantiate an In Memory Cache with the Contentful type policies */
export const makeCache = () =>
  new InMemoryCache({
    typePolicies: {
      ...Object.fromEntries([
        ...ContentfulIdTypes.map((type) => [
          type,
          { keyFields: ['sys', ['id']] },
        ]),
      ]),
      // TouchpointNotificationConfig JSON blobs do not have identifiers,
      // therefore we must explicitly disable the merge behavior for this field.
      SequentialTouchpointTemplate: {
        fields: {
          notificationConfig: {
            merge: false,
          },
        },
      },
    },
  });

interface ApolloClientConfig {
  token?: string | null;
  tenantId?: string | null;
  programId?: string | null;
  contentfulToken?: string | null;
  contentfulSpace?: string | null;
  contentfulEnvironment?: string | null;
}

interface CreateApolloClientReturn {
  client: ApolloClient<any>;
  update: (args: ApolloClientConfig) => void;
}

/**
 *
 * @param token The current JWT authorization token for the Caire API
 * @param program The current Caire Program UUID
 * @param tenantId The current tenant UUID
 * @param contentfulToken The current JWT authorization token for the Contentful API
 * @param contentfulSpace The current Contentful Space ID
 * @param contentfulEnvironment The current Contentful Environment name
 * @returns ApolloClient
 */
export const createApolloClient = (
  config: ApolloClientConfig = {}
): CreateApolloClientReturn => {
  const configuredMemoryCache = makeCache();
  let {
    token,
    programId,
    tenantId,
    contentfulToken,
    contentfulSpace,
    contentfulEnvironment,
  } = config;

  // Allow updating the client configuration in-place, without recreating the client instance.
  const update = (options: ApolloClientConfig) => {
    token = options.token ?? token;
    programId = options.programId ?? programId;
    tenantId = options.tenantId ?? tenantId;
    contentfulToken = options.contentfulToken ?? contentfulToken;
    contentfulSpace = options.contentfulSpace ?? contentfulSpace;
    contentfulEnvironment =
      options.contentfulEnvironment ?? contentfulEnvironment;
  };

  const caireAuthTokenLink = setContext((_, previousContext) =>
    addContextAuthHeader(token, previousContext)
  );

  const contentfulAuthTokenLink = setContext((_, previousContext) =>
    addContextAuthHeader(contentfulToken, previousContext)
  );

  const contentfulHttpLink = createHttpLink({
    uri: () => {
      return makeContentfulApiUrl(
        process.env.NEXT_PUBLIC_CONTENTFUL_API_URL ||
          'https://graphql.contentful.com',
        contentfulSpace ?? '',
        contentfulEnvironment ?? undefined
      );
    },
    fetch,
  });

  const caireProgramHeaderLink = setContext((_, previousContext) =>
    addContextHeader('x-caire-current-program', programId, previousContext)
  );

  const caireTenantHeaderLink = setContext((_, previousContext) =>
    addContextHeader('x-caire-current-tenant', tenantId, previousContext)
  );

  // Remove the `__typename` field from all variables in a mutation request.
  // Allows for sending query response data straight back to the server in a mutation
  // without errors caused by the presence of `__typename` fields.
  const removeTypenameLink = removeTypenameFromVariables();

  // Split the GraphQL backend target between Caire API and Contentful
  // based on the `apiName` context key
  const backendSplitLink = ApolloLink.split(
    (operation) => operation.getContext().apiName === 'contentful',
    ApolloLink.from([contentfulAuthTokenLink, contentfulHttpLink]),
    ApolloLink.from([
      caireAuthTokenLink,
      caireProgramHeaderLink,
      caireTenantHeaderLink,
      removeTypenameLink,
      caireHttpLink,
    ])
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      devConsoleErrorReportLink,
      dataDogErrorReportLink,
      backendSplitLink,
    ]),
    cache: configuredMemoryCache,
    ssrMode: typeof window === 'undefined',
    connectToDevTools:
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? false : true,
  });

  return {
    update,
    client,
  };
};

// We create a single instance of the Apollo client, for reuse throughout the lifetime of the app.
const clientInstance = createApolloClient();

/**
 * Expose the ApolloClient instance, so it can be accessed in distinct React components,
 * and in non-React hook code, e.g. SurveyJS.
 * @returns ApolloClient The current ApolloClient instance, and update() function to update the client configuration.
 */
export const getApolloClient = () => clientInstance;
