import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
} from 'react';
import { useRouter } from 'next/router';
import { DeepLinkObjectType, TaskRelatedObject } from '@/lib/graphql/types';
import { useResolveDeepLinkLazyQuery } from '@/lib/graphql/shared';
import { objectForPathname } from '@/lib/utils/path-object';
import { decodeCaireJwt } from '@/lib/auth/jwt';
import { consolidateDeepLinkTarget } from '@/lib/utils/deeplink';

type CombinedObjectType = TaskRelatedObject | DeepLinkObjectType | 'Test';

type StoredDeepLinkToken = {
  slug: string;
  token: string;
  type: CombinedObjectType;
  identifier: string | null;
};

interface DeepLinkAuthContextType {
  tokens: StoredDeepLinkToken[];
  isTest: boolean;
  deeplinkResolverPath: string;
  clearTokens: () => void;
  addObjectToken(
    slug: string,
    token: string,
    type: CombinedObjectType,
    identifier: string | null
  ): void;
}

const DeepLinkAuthContext = createContext<DeepLinkAuthContextType>({
  tokens: [],
  isTest: false,
  deeplinkResolverPath: '/dl',
  // This context provider defaults to silent stub functions, so as to allow
  // for components to support it from apps or routes that don't support deep linking
  clearTokens: () => {},
  addObjectToken: () => {},
});

interface DeepLinkAuthorizationProviderProps extends PropsWithChildren {
  testToken?: string;
  deeplinkResolverPath?: string;
}

export const DeepLinkAuthorizationProvider: FunctionComponent<
  DeepLinkAuthorizationProviderProps
> = ({ children, testToken, deeplinkResolverPath = '/dl' }) => {
  const [tokens, setTokens] = useState<Record<string, StoredDeepLinkToken>>(
    testToken
      ? {
          test: {
            slug: 'test',
            token: testToken,
            type: 'Test',
            identifier: null,
          },
        }
      : {}
  );

  const isTest = !!testToken;

  /**
   * Clear all tokens from local storage
   */
  const clearTokens = () => setTokens({});

  /**
   * Add a token associated with a task associated object
   */
  const addObjectToken = useCallback(
    (
      slug: string,
      token: string,
      type: CombinedObjectType,
      identifier: string | null
    ) => {
      setTokens((currentTokens) => ({
        ...currentTokens,
        ...{
          slug: {
            slug,
            token,
            type,
            identifier,
          },
        },
      }));
    },
    [setTokens]
  );

  return (
    <DeepLinkAuthContext.Provider
      value={{
        tokens: Object.values(tokens),
        isTest,
        deeplinkResolverPath,
        clearTokens,
        addObjectToken,
      }}>
      {children}
    </DeepLinkAuthContext.Provider>
  );
};

/**
 * Access Deep Link authorization tokens for the current route.
 * @returns A JWT, if one is set for the current route.
 */
export const useDeepLinkAuthorization = () => {
  const { tokens, isTest, clearTokens, addObjectToken, deeplinkResolverPath } =
    useContext(DeepLinkAuthContext);
  const { isReady, pathname, query, push } = useRouter();

  const tokenObject = useMemo(() => {
    // Look up the object type and ID from the well-known feature path.
    // We also return `Test` type tokens, which can only be created through props in tests.
    const { objectType, objectId } = objectForPathname(pathname, query) ?? {};

    if (isTest) {
      return tokens.find((_) => _.type === 'Test') ?? null;
    }

    if (objectType) {
      return (
        tokens.find(
          (_) =>
            _.type === objectType &&
            (_.identifier === objectId || _.identifier === null)
        ) ?? null
      );
    }

    return null;
  }, [tokens, isTest, pathname, query]);

  const slug = tokenObject?.slug;
  const token = tokenObject?.token ?? null;

  const authUser = useMemo(
    () => (token ? decodeCaireJwt(token) : null),
    [token]
  );

  const [refreshToken] = useResolveDeepLinkLazyQuery({
    onCompleted: (data) => {
      if (!slug) {
        // guard against slug being cleared out of sync with this promise handler
        return;
      }

      // If needed data is present and resolved, re-add the token against the same link ID.
      if (data.resolveDeepLink.token && data.resolveDeepLink.target) {
        const target = consolidateDeepLinkTarget(data.resolveDeepLink.target);

        if (target) {
          addObjectToken(
            slug,
            data.resolveDeepLink.token,
            target.objectType,
            target.objectId
          );
          return;
        }
      }

      // If there is no token returned, then we assume it has expired
      // and redirect the user to the deep link page where errors can be
      // handled more granularly.
      redirectToDeepLinkUi();
      return;
    },
    onError: (err) => {
      redirectToDeepLinkUi();
    },
  });

  const redirectToDeepLinkUi = useCallback(() => {
    if (!deeplinkResolverPath || !slug) {
      return;
    }

    // Redirect to the deep link page with the current slug
    push(`${deeplinkResolverPath}?link=${slug}`);
  }, [push, deeplinkResolverPath, slug]);

  useEffect(() => {
    if (!authUser || !slug) {
      return;
    }

    const delta = Math.max(
      // Prevent negative delta if token already expired:
      1,
      // Subtract extra 30 seconds to ensure the token is refreshed before it expires:
      authUser.expires.getTime() - (Date.now() + 30000)
    );

    const timeout = setTimeout(() => {
      refreshToken({
        variables: {
          linkPath: slug,
        },
      });
    }, delta);

    return () => clearTimeout(timeout);
  }, [refreshToken, slug, authUser]);

  return {
    loading: !isReady,
    token,
    authUser,
    hasToken: !!token,
    logOut: () => clearTokens(),
    addObjectToken,
  };
};
