import decode from 'jwt-claims';

export type CaireUserTokenClaims = {
  expires: Date;
  email?: string | null;
  emailVerified?: boolean;
  // TODO: SSO is to be implemented. For now, assume that all users are not SSO users. [CRE-2563]
  isSSOAuthUser?: boolean;
  authId: string | null;
  staffId?: string | null;
  patientId?: string | null;
  programs?: string[];
};

/**
 * Decode a JWT and map claims into a CaireUserTokenClaims object.
 *
 * Note that JWTs from different sources — Cognito or DeepLink — may not have
 * all the same claims.
 *
 * @param token A JWT from Cognito or DeepLink
 * @returns Relevant claims from the JWT
 */
export const decodeCaireJwt = (token: string): CaireUserTokenClaims | null => {
  try {
    const decodedToken = decode(token);

    // Decode the list of programs the user is enrolled in:
    const serializedPrograms: string = decodedToken['custom:programs'] ?? '{}';
    const enrolledPrograms: Record<string, string> =
      JSON.parse(serializedPrograms);
    const activePrograms = Object.entries(enrolledPrograms).flatMap(
      ([program, status]) => (status === 'active' ? program : [])
    );

    return {
      authId: decodedToken['cognito:username'] ?? null,
      expires: new Date(decodedToken.exp * 1000),
      email: decodedToken.email ?? null,
      emailVerified: decodedToken.email_verified ?? false,
      isSSOAuthUser: false,
      staffId: decodedToken['custom:staff_id'] ?? null,
      patientId: decodedToken['custom:patient_id'] ?? null,
      programs: activePrograms,
    };
  } catch (e) {
    return null;
  }
};
