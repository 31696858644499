import { DeepLinkObjectType, TaskRelatedObject } from '@/lib/graphql/types';
import { ResolveDeepLinkQuery } from '@/lib/graphql/shared';

export type DeepLinkTarget = NonNullable<
  ResolveDeepLinkQuery['resolveDeepLink']['target']
>;
export type ConsolidatedDeepLinkTarget =
  | {
      objectType: TaskRelatedObject;
      objectId: string | null;
      taskId: string;
    }
  | {
      objectType: DeepLinkObjectType;
      objectId: string | null;
      taskId: null;
    };

export const consolidateDeepLinkTarget = (
  target: DeepLinkTarget
): ConsolidatedDeepLinkTarget | null => {
  if (target.__typename == 'DeepLinkTaskTarget') {
    if (!target.relatedObjectType) {
      return null;
    }

    return {
      objectType: target.relatedObjectType,
      objectId: target.relatedObjectId ?? null,
      taskId: target.taskId,
    };
  }

  if (target.__typename == 'DeepLinkObjectTarget') {
    return {
      objectType: target.objectType,
      objectId: target.objectId ?? null,
      taskId: null,
    };
  }

  return null;
};
