import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useIdle } from '@mantine/hooks';
import {
  useCognitoAction,
  useCognitoAuth,
  useCognitoSession,
} from '@/contexts/Cognito';
import useLogOut from '@/hooks/useLogOut';

const IDLE_SESSION_MINUTES =
  parseInt(process.env.NEXT_PUBLIC_IDLE_SESSION_MINUTES ?? '') || 60;
const IDLE_SESSION_MS = IDLE_SESSION_MINUTES * 60 * 1000;
const IDLE_PULSE_MS = 10 * 1000;

export const IdleSessionTimeout: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { setError } = useCognitoAuth();
  const logOut = useLogOut();
  const { authUser, authenticated } = useCognitoSession();
  const tokenExpires = authUser?.expires.getTime();

  const { possiblyRefreshAccessToken } = useCognitoAction();

  // Consider idle after IDLE_PULSE seconds
  const isIdle = useIdle(IDLE_PULSE_MS, { initialState: false });

  // Force logout after IDLE_SESSION_MINUTES minutes of inactivity
  const isForceLogoutRequired = useIdle(IDLE_SESSION_MS, {
    initialState: false,
  });

  useEffect(() => {
    if (isIdle) {
      // don't refresh while idle
      return;
    }

    if (tokenExpires) {
      const delta = tokenExpires - Date.now();
      // Offset 30 seconds to ensure the token is refreshed cleanly before it expires:
      const delay = Math.max(1, delta - 30);
      const timeout = setTimeout(() => {
        possiblyRefreshAccessToken();
      }, delay);

      return () => clearTimeout(timeout);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps
       -- don't include possiblyRefreshAccessToken in the deps array as it's unstable */
  }, [isIdle, tokenExpires]);

  useEffect(() => {
    if (isForceLogoutRequired && authenticated) {
      // This error will be displayed to the user on the login page
      setError(
        new Error(
          `Your session automatically expired after ${IDLE_SESSION_MINUTES} minutes idle. Please log in again.`
        )
      );

      // We pass stay in place rather than automatically redirecting to the login page
      // so that the user is only redirect if auth is _required_ for their current page,
      // and so that the isAuthenticated handler for that page might capture the current
      // URL and hook in a 'return to' URL to restore the user's place.
      logOut({ stayInPlace: true });
    }
  }, [isForceLogoutRequired, authenticated, setError, logOut]);

  return children;
};
