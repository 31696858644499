import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import { ApolloProvider } from '@apollo/client';
import { useCurrentProgramContext } from '@/contexts/ProgramSwitch';
import { useAuthentication } from '@/contexts/Authentication';
import { useTenantConfig, useTenantId } from '@/contexts/TenantConfig';
import { getApolloClient } from '@/lib/graphql/apollo-client';

/**
 * A wrapper for the ApolloProvider, instantiated with a singleton client instance,
 * that is updated with auth, tenant and program context values by ApolloClientConfigurator.
 */
export const AuthenticatedApolloProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const clientRef = useRef<ReturnType<typeof getApolloClient> | never>(
    null as never
  );

  if (clientRef.current === null) {
    clientRef.current = getApolloClient();
  }

  return (
    <ApolloProvider client={clientRef.current.client}>
      {children}
    </ApolloProvider>
  );
};

export const ApolloClientAuthenticationContext = createContext({
  clientAuthenticated: false,
});

/**
 * A companion component to be nested inside the provider hierarchy, it updates the singleton
 * Apollo client instance with live values for auth token, tenant and program context.
 */
export const ApolloClientConfigurator: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { authToken } = useAuthentication();
  const { currentProgramId } = useCurrentProgramContext();
  const tenantId = useTenantId();
  const [clientAuthenticated, setClientAuthenticated] = useState(false);
  const { contentfulConfig } = useTenantConfig();
  const { token, space, environment } = contentfulConfig ?? {};

  const clientRef = useRef<ReturnType<typeof getApolloClient> | never>(
    null as never
  );

  if (clientRef.current === null) {
    clientRef.current = getApolloClient();

    // Update the client with the initial context values.
    clientRef.current.update({
      token: authToken ?? undefined,
      tenantId,
      programId: currentProgramId,
      contentfulToken: token,
      contentfulSpace: space,
      contentfulEnvironment: environment,
    });
    setClientAuthenticated(!!authToken);
  }

  // Update the client config every time the config sources change.
  useEffect(() => {
    const { token, space, environment } = contentfulConfig ?? {};
    clientRef.current.update({
      token: authToken ?? undefined,
      tenantId,
      programId: currentProgramId,
      contentfulToken: token,
      contentfulSpace: space,
      contentfulEnvironment: environment,
    });
    setClientAuthenticated(!!authToken);
  }, [authToken, currentProgramId, tenantId, contentfulConfig]);

  return (
    <ApolloClientAuthenticationContext.Provider value={{ clientAuthenticated }}>
      {children}
    </ApolloClientAuthenticationContext.Provider>
  );
};

export const useApolloClientAuthentication = () => {
  return useContext(ApolloClientAuthenticationContext);
};
